function scrollNext() {
    const element = document.getElementById('comparison-chart-container');
    // element.scrollLeft += 200;
    element.scrollBy({
        left: 200,
        // behavior: "smooth",
      });
}

function scrollPrev() {
    const element = document.getElementById('comparison-chart-container');
    
    // element.scrollLeft -= 200;
    element.scrollBy({
        left: -200,
        // behavior: "smooth",
      });
}

const comparisonLeftArrow = document.querySelector('.comparison-chart-left-js');
const comparisonRightArrow = document.querySelector('.comparison-chart-right-js');

// document.querySelector('.comparison-chart-left-js').addEventListener('click', prevSlide);
// document.querySelector('.comparison-chart-right-js').addEventListener('click', nextSlide);

if (comparisonLeftArrow){
    comparisonLeftArrow.onclick = () => {
        scrollPrev();
    }
}

if (comparisonRightArrow) {
    comparisonRightArrow.onclick = () => {
        scrollNext();
    }
}

